<template>
  <section class="container mb-14 sm:mb-20">
    <div class="container__inner pt-6 md:pt-12">
      <h1 class="text-royalblue-900 mb-10">Prices</h1>
      <table class="mb-4">
        <tr>
          <td>
            Initial neurological/functional consultation, assessment, treatment
            and plan
          </td>
          <td>&pound;95.00</td>
        </tr>
        <tr>
          <td>Follow up appointments</td>
          <td>From &pound;85.00 per session</td>
        </tr>
        <tr>
          <td>Treatment requiring therapist and assistant</td>
          <td>Starting at &pound;95.00</td>
        </tr>
        <tr>
          <td>Letter and report writing</td>
          <td>&pound;85.00/hr</td>
        </tr>
        <tr>
          <td class="border-0" colspan="2">
            If more than 15 minutes travel time from therapists base a travel
            charge will be discussed and agreed prior to treatment commencing.
          </td>
        </tr>
      </table>
      <p>
        Prices applicable to 2024, accurate price confirmation at initial
        contact
      </p>
    </div>
  </section>
</template>

<style lang="scss" scoped>
table {
  @apply w-full rounded-lg shadow-table-shadow overflow-hidden;

  tr {
    > td:last-child:not(.border-0) {
      @apply border-l-2;
    }

    &:last-child td {
      @apply border-b-0;
    }
  }

  td {
    @apply bg-denim-50 border-b-2 border-royalblue-200 py-3 px-4;
  }
}
</style>

<script>
export default {
  name: "Prices",
};
</script>
